.file-uploader-enter {
  opacity: 0.01;
  max-height: 0;
}

.file-uploader-enter.file-uploader-enter-active {
  opacity: 1;
  max-height: 200px;
  transition: all 1000ms linear;
}

.file-uploader-leave {
  opacity: 1;
  max-height: 200px;
}

.file-uploader-leave.file-uploader-leave-active {
  opacity: 0.01;
  max-height: 0;
  transition: all 500ms linear;
}

.filepond--drop-label {
  min-height: 40px !important;
}

