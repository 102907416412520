.drawer {

}

.paper {
  width: 280px;
}

.drawer-header {
  align-items: center;
  background-color: #f0be32;
  display: flex;
  height: 63px;
  padding: 0 2px 0 16px;
  justify-content: space-between;
}

.chevron-icon {
  color: black;
}

div.menu-item:hover {
  background-color: #f0be3294;
}
