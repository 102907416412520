.attachment-info-appear {
  opacity: 0.01;
  max-height: 0;
}

.attachment-info-appear.attachment-info-appear-active {
  opacity: 1;
  max-height: 200px;
  transition: all 500ms linear;
}
